<template>
    <div class="pdf-viewer-2">
        <div style="position: absolute" id="viewerContainer" class="custom-scroll">
            <div id="viewer" class="pdfViewer"></div>
        </div>
        <div v-if="loading" class="pdf-loading loading"></div>
        <div v-if="pageCount" class="actions-container">
            <div class="actions-container-navigation">
                <button @click="onPrevPage" class="pdf-button" id="prev">
                    <ArrowRightIcon class="rotate-180" fill="#fff" />
                </button>
                <span
                    ><span>{{ currentPage }}</span> / <span>{{ pageCount }}</span></span
                >
                <button @click="onNextPage" class="pdf-button" id="next">
                    <ArrowRightIcon fill="#fff" />
                </button>
            </div>
            <flex-container class="actions-container-zoom" align="center">
                <button @click="pdfViewZoomIn" class="pdf-button mr-5" id="prev">
                    <PlusIcon class="rotate-180" fill="#fff" />
                </button>
                <button @click="pdfViewZoomOut" class="pdf-button mr-5" id="prev">
                    <MinusIcon class="rotate-180" fill="#fff" />
                </button>
                <DefaultDropdown
                    style-modifier="bottom"
                    @update="updateScale"
                    :active-case="
                        (pdfViewer &&
                            pdfViewer.currentScaleValue &&
                            scaleValues.find(v => v.id === pdfViewer.currentScaleValue)) ||
                        (pdfViewer.currentScaleValue && `${parseInt(pdfViewer.currentScaleValue * 100)}%`)
                    "
                    :cases="scaleValues"
                />
            </flex-container>

            <flex-container justify="flex-end" class="">
                <DefaultInput placeholder="Search" bloated v-model="search" />
                <button v-if="allowDownload" @click="download" class="pdf-button ml-5">
                    <DownloadIcon width="20" fill="#fff" />
                </button>
                <button @click="togglePresentationMode" class="pdf-button ml-5">
                    <FullScreenIcon width="20" fill="#fff" />
                </button>
            </flex-container>
        </div>
    </div>
</template>

<script>
import FlexContainer from "@components/Containers/FlexContainer.vue"
import { DownloadManager } from "@components/Files/utils/download-manager"
import DefaultDropdown from "@components/Forms/DefaultDropdown.vue"
import DefaultInput from "@components/Forms/DefaultInput.vue"
import ArrowRightIcon from "@icons/ArrowRightIcon.vue"
import DownloadIcon from "@icons/DownloadIcon.vue"
import FullScreenIcon from "@icons/FullScreenIcon.vue"
import MinusIcon from "@icons/MinusIcon.vue"
import PlusIcon from "@icons/PlusIcon.vue"
import checkDevice from "@mixins/checkDevice"

const WORKER_URL = new URL("/build/js/pdf.worker.min.js", window.location).href
const CMAP_URL = "https://cdn.jsdelivr.net/npm/pdfjs-dist@3.11.174/cmaps/"
const CMAP_PACKED = true

const SANDBOX_BUNDLE_SRC = new URL("/build/js/pdf.sandbox.js", window.location)
const ENABLE_XFA = true

const DEFAULT_SCALE_DELTA = 1.1
const MIN_SCALE = 0.25
const MAX_SCALE = 10.0
const DEFAULT_SCALE_VALUE = "auto"
const DEFAULT_ZOOM_DELAY = 400

const SCALE_VALUES = [
    {
        name: "Auto Zoom",
        id: "auto"
    },
    {
        name: "Actual Size",
        id: "page-actual"
    },
    {
        name: "Page Fit",
        id: "page-fit"
    },
    {
        name: "Page Width",
        id: "page-width"
    },
    {
        name: "50%",
        id: "0.5"
    },
    {
        name: "75%",
        id: "0.75"
    },
    {
        name: "100%",
        id: "1"
    },
    {
        name: "125%",
        id: "1.25"
    },
    {
        name: "150%",
        id: "1.5"
    },
    {
        name: "200%",
        id: "2"
    },
    {
        name: "300%",
        id: "3"
    },
    {
        name: "400%",
        id: "4"
    }
]

export default {
    components: {
        DownloadIcon,
        FullScreenIcon,
        DefaultInput,
        DefaultDropdown,
        MinusIcon,
        PlusIcon,
        FlexContainer,
        ArrowRightIcon
    },
    props: {
        path: {
            type: String,
            default: ""
        },
        allowDownload: {
            type: Boolean,
            default: false
        }
    },
    name: "PdfViewer",
    mixins: [checkDevice],
    data() {
        return {
            pdfViewer: null,
            currentPage: 1,
            pageCount: null,
            container: null,
            eventBus: null,
            currentScale: 1,
            pdfLinkService: null,
            pdfScriptingManager: null,
            loading: true,
            scaleValues: SCALE_VALUES,
            supportsMouseWheelZoomCtrlKey: true,
            supportsMouseWheelZoomMetaKey: true,
            supportsPinchToZoom: true,
            isCtrlKeyDown: false,
            caretBrowsing: null,
            pdfDocument: null,
            isScrolling: false,
            wheelUnusedTicks: 0,
            wheelUnusedFactor: 0,
            downloadManager: new DownloadManager(),
            search: ""
        }
    },
    mounted() {
        window.addEventListener("wheel", this.onWheel, {
            passive: false
        })
        setTimeout(() => {
            if (this.$el) {
                this.$el.style.minHeight = `${window.innerHeight * 0.8}px`
            }
            try {
                this.onViewerInit()
            } catch (e) {
                console.log(e)
            }
        })
    },
    methods: {
        getFilenameFromUrl(u) {
            const [url] = u.split(/[#?]/, 1)
            return decodeURI(url.substring(url.lastIndexOf("/") + 1))
        },
        async download() {
            let data
            try {
                data = await this.pdfDocument.getData()
            } catch {
                // When the PDF document isn't ready, simply download using the URL.
            }
            this.downloadManager.download(data, window.app_root_url_no_locale, this.getFilenameFromUrl(this.path))
        },
        togglePresentationMode() {
            this.container.requestFullscreen()
        },
        searchText() {
            this.eventBus.dispatch("find", { type: "", query: this.search })
        },
        normalizeWheelEventDirection(evt) {
            let delta = Math.hypot(evt.deltaX, evt.deltaY)
            const angle = Math.atan2(evt.deltaY, evt.deltaX)
            if (-0.25 * Math.PI < angle && angle < 0.75 * Math.PI) {
                // All that is left-up oriented has to change the sign.
                delta = -delta
            }
            return delta
        },
        onWheel(evt) {
            const { pdfViewer, supportsMouseWheelZoomCtrlKey, supportsMouseWheelZoomMetaKey, supportsPinchToZoom } =
                this

            if (!pdfViewer || pdfViewer.isInPresentationMode) {
                return
            }

            // Pinch-to-zoom on a trackpad maps to a wheel event with ctrlKey set to true
            // https://developer.mozilla.org/en-US/docs/Web/API/WheelEvent#browser_compatibility
            // Hence if ctrlKey is true but ctrl key hasn't been pressed then we can
            // infer that we have a pinch-to-zoom.
            // But the ctrlKey could have been pressed outside of the browser window,
            // hence we try to do some magic to guess if the scaleFactor is likely coming
            // from a pinch-to-zoom or not.

            // It is important that we query deltaMode before delta{X,Y}, so that
            // Firefox doesn't switch to DOM_DELTA_PIXEL mode for compat with other
            // browsers, see https://bugzilla.mozilla.org/show_bug.cgi?id=1392460.
            const deltaMode = evt.deltaMode

            // The following formula is a bit strange but it comes from:
            // https://searchfox.org/mozilla-central/rev/d62c4c4d5547064487006a1506287da394b64724/widget/InputData.cpp#618-626
            let scaleFactor = Math.exp(-evt.deltaY / 100)

            const isBuiltInMac = this.isMac()
            const isPinchToZoom =
                evt.ctrlKey &&
                !this.isCtrlKeyDown &&
                deltaMode === WheelEvent.DOM_DELTA_PIXEL &&
                evt.deltaX === 0 &&
                (Math.abs(scaleFactor - 1) < 0.05 || isBuiltInMac) &&
                evt.deltaZ === 0
            const origin = [evt.clientX, evt.clientY]

            if (
                isPinchToZoom ||
                (evt.ctrlKey && supportsMouseWheelZoomCtrlKey) ||
                (evt.metaKey && supportsMouseWheelZoomMetaKey)
            ) {
                // Only zoom the pages, not the entire viewer.
                evt.preventDefault()

                // NOTE: this check must be placed *after* preventDefault.
                if (this.isScrolling || document.visibilityState === "hidden") {
                    return
                }

                if (isPinchToZoom && supportsPinchToZoom) {
                    scaleFactor = this._accumulateFactor(pdfViewer.currentScale, scaleFactor, "wheelUnusedFactor")
                    this.updateZoom(null, scaleFactor, origin)
                } else {
                    const delta = this.normalizeWheelEventDirection(evt)

                    let ticks = 0
                    if (deltaMode === WheelEvent.DOM_DELTA_LINE || deltaMode === WheelEvent.DOM_DELTA_PAGE) {
                        // For line-based devices, use one tick per event, because different
                        // OSs have different defaults for the number lines. But we generally
                        // want one "clicky" roll of the wheel (which produces one event) to
                        // adjust the zoom by one step.
                        //
                        // If we're getting fractional lines (I can't think of a scenario
                        // this might actually happen), be safe and use the accumulator.
                        ticks =
                            Math.abs(delta) >= 1 ? Math.sign(delta) : this._accumulateTicks(delta, "wheelUnusedTicks")
                    } else {
                        // pixel-based devices
                        const PIXELS_PER_LINE_SCALE = 30
                        ticks = this._accumulateTicks(delta / PIXELS_PER_LINE_SCALE, "wheelUnusedTicks")
                    }

                    this.updateZoom(ticks, null, origin)
                }
            }
        },
        _accumulateTicks(ticks, prop) {
            // If the direction changed, reset the accumulated ticks.
            if ((this[prop] > 0 && ticks < 0) || (this[prop] < 0 && ticks > 0)) {
                this[prop] = 0
            }
            this[prop] += ticks
            const wholeTicks = Math.trunc(this[prop])
            this[prop] -= wholeTicks
            return wholeTicks
        },
        _accumulateFactor(previousScale, factor, prop) {
            if (factor === 1) {
                return 1
            }
            // If the direction changed, reset the accumulated factor.
            if ((this[prop] > 1 && factor < 1) || (this[prop] < 1 && factor > 1)) {
                this[prop] = 1
            }

            const newFactor = Math.floor(previousScale * factor * this[prop] * 100) / (100 * previousScale)
            this[prop] = factor / newFactor

            return newFactor
        },
        updateScale({ id }) {
            this.pdfViewer.currentScaleValue = id
        },
        pdfViewZoomIn(ticks) {
            let newScale = this.pdfViewer.currentScale
            do {
                newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(2)
                newScale = Math.ceil(newScale * 10) / 10
                newScale = Math.min(MAX_SCALE, newScale)
            } while (--ticks && newScale < MAX_SCALE)
            this.pdfViewer.currentScaleValue = newScale
        },
        updateZoom(steps, scaleFactor, origin) {
            if (this.pdfViewer.isInPresentationMode) {
                return
            }
            this.pdfViewer.updateScale({
                drawingDelay: DEFAULT_ZOOM_DELAY,
                steps,
                scaleFactor,
                origin
            })
        },
        pdfViewZoomOut(ticks) {
            let newScale = this.pdfViewer.currentScale
            do {
                newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(2)
                newScale = Math.floor(newScale * 10) / 10
                newScale = Math.max(MIN_SCALE, newScale)
            } while (--ticks && newScale > MIN_SCALE)
            this.pdfViewer.currentScaleValue = newScale
        },
        onPrevPage() {
            this.pdfViewer.currentPageNumber = this.currentPage - 1
        },
        onNextPage() {
            this.pdfViewer.currentPageNumber = this.currentPage + 1
        },
        async onViewerInit() {
            console.log("init Viewers")

            const pdfjsLib = window.pdfjsLib
            const pdfjsViewer = window.pdfjsViewer

            if (!pdfjsLib || !pdfjsViewer) {
                setTimeout(() => {
                    this.onViewerInit()
                }, 200)
                return
            }

            pdfjsLib.GlobalWorkerOptions.workerSrc = WORKER_URL

            this.container = this.$el.querySelector("#viewerContainer")

            this.eventBus = new pdfjsViewer.EventBus()

            // (Optionally) enable hyperlinks within PDF files.
            this.pdfLinkService = new pdfjsViewer.PDFLinkService({
                eventBus: this.eventBus
            })

            // (Optionally) enable find controller.
            this.pdfFindController = new pdfjsViewer.PDFFindController({
                eventBus: this.eventBus,
                linkService: this.pdfLinkService
            })

            // (Optionally) enable scripting support.
            this.pdfScriptingManager = new pdfjsViewer.PDFScriptingManager({
                eventBus: this.eventBus,
                sandboxBundleSrc: SANDBOX_BUNDLE_SRC
            })

            this.pdfViewer = new pdfjsViewer.PDFViewer({
                container: this.container,
                eventBus: this.eventBus,
                linkService: this.pdfLinkService,
                findController: this.pdfFindController,
                scriptingManager: this.pdfScriptingManager
            })

            this.pdfLinkService.setViewer(this.pdfViewer)
            this.pdfScriptingManager.setViewer(this.pdfViewer)

            this.eventBus.on("pagesinit", () => {
                // We can use pdfViewer now, e.g. let's change default scale.
                this.pdfViewer.currentScaleValue = DEFAULT_SCALE_VALUE
            })

            this.eventBus.on("pagechanging", e => {
                this.currentPage = e.pageNumber
            })

            // Loading document.
            const loadingTask = pdfjsLib.getDocument({
                url: this.path,
                cMapUrl: CMAP_URL,
                cMapPacked: CMAP_PACKED,
                enableXfa: ENABLE_XFA
            })

            this.pdfDocument = await loadingTask.promise
            // Document loaded, specifying document for the viewer and
            // the (optional) linkService.

            this.pageCount = this.pdfDocument.numPages
            this.pdfViewer.setDocument(this.pdfDocument)

            console.log(`document`)

            this.pdfLinkService.setDocument(this.pdfDocument, null)

            this.loading = false
        }
    },
    watch: {
        search(val) {
            if (val) {
                this.searchText()
            }
        }
    }
}
</script>

<style scoped lang="sass">
.actions-container-zoom
    @media (max-width: 722px)
        display: none
.pdf-button
    width: 32px
    min-width: 32px
    height: 32px
    border: 1px solid #777777
    background: #545454
    display: flex
    align-items: center
    justify-content: center
    border-radius: 3px
    &:hover
        background-color: #606060
.pdf-loading
    position: absolute
    top: 50%
    left: 50%
.pdf-viewer-2
    position: relative
    min-height: 70vh
    width: 100%
    margin-bottom: 32px
    .custom-scroll::-webkit-scrollbar-track
        background-color: #eee
        width: 8px
        height: 8px

    .custom-scroll::-webkit-scrollbar-thumb
        -webkit-border-radius: 0
        border-radius: 0
        background-color: rgba(141, 141, 141, 0.7)
    &::v-deep
        .default-input
            max-width: 200px
            height: 32px
            &__search-icon
                top: 7px
                left: 8px
                width: 18px
                path
                    fill: #fff
            &__input
                background-color: #545454 !important
                color: #fff
                border: 1px solid #777777 !important
                height: 32px
                border-radius: 3px
                text-indent: 32px
                font-size: 12px
                &::placeholder
                    color: #fff
        .default-dropdown
            z-index: 4
        .default-dropdown__modal
            min-width: 140px
        .default-dropdown__current
            padding: 0 10px
            font-size: 13px
            height: 32px
            border: 1px solid #777777 !important
            background: #545454 !important
            color: #fff
            border-radius: 3px
            svg
                width: 18px
                min-width: 18px !important
                path
                    fill: #fff !important
        .loadingIcon::after
            width: 30px !important
            height: 30px !important
            top: 50% !important
            left: 50% !important
            background: url(@images/portal/loader-icon-dark.svg) center no-repeat !important
#viewerContainer
    background-color: #eee
    overflow: auto
    position: absolute
    width: 100%
    height: 100%
    padding-top: 20px
    padding-bottom: 40px
.actions-container-navigation
    display: grid
    align-items: center
    grid-template-columns: 32px 80px 32px
.actions-container
    padding: 0 10px
    position: absolute
    z-index: 99
    display: grid
    align-items: center
    grid-template-columns: 1fr 1fr 1fr
    text-align: center
    bottom: -40px
    border-radius: 0 0 4px 4px
    background-color: rgba(#000000, 70%)
    color: #fff
    left: 0
    right: 0
    height: 42px
    font-size: 15px
    span
        font-size: 14px
    button
        font-family: Inter, sans-serif
    @media (max-width: 722px)
        grid-template-columns: 1fr 1fr
</style>
