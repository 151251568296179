<template>
    <div
        class="players-wrapper"
        :class="{
            ['player-is-' + parent]: parent
        }"
    >
        <AlertBadConnection />
        <LoaderBlock v-if="!isReady" />
        <template v-else>
            <!-- Status file           -->
            <VideoUploadProgress
                v-if="displayProgressUpload"
                :video-src="videoSrc"
                :playback="playback"
                @progress:toggle-hide="isUploadedVideo = $event"
            />

            <!--            &lt;!&ndash; Is Audio Hls  &ndash;&gt;
            <HlsAudioPlayer
                v-else-if="playback && playback.is_audio_hls && !isIOS()"
                :audioUrl="videoSrc"
                :item="playback"
                :infoUnitId="infoUnitId"
            />-->

            <!-- Is HLS    -->
            <VideojsPlayer
                v-else-if="(videoType.isHls || (playback && playback.is_audio_hls && isIOS())) && !didRerender"
                :video-link="videoSrc"
                :videoType="videoType"
                :uuid="uuid"
                :preview="preview"
                :is-audio-hls="playback && playback.is_audio_hls && isIOS()"
                :isLive="isLive"
                :displayMobileVersion="displayMobileVersion"
                :duration="duration"
                :orientation="orientation"
                :liveStartTime="liveStartTime"
                :liveTrimEnding="liveTrimEnding"
                :infoUnitId="infoUnitId"
                :seek-to="seekTo"
                @rerender="rerenderPlayer"
                @ready="playerReady"
                @start="videoStarted"
                @pause="handleCollectedData()"
                @ended="videoEnded()"
                @timeupdate="onPlayerTimeupdate($event)"
            />

            <!-- Is Bunny -->
            <BunnyPlayer
                v-else-if="videoType.isBunny"
                :videoUrl="videoSrc"
                :parent="parent"
                :infoUnitId="infoUnitId"
                @play="videoStarted"
                @pause="handleCollectedData()"
                @ended="videoEnded()"
                @timeupdate="onPlayerTimeupdate($event)"
            />

            <!-- Is Youtube           -->
            <YouTubeVideoPlayer
                v-else-if="videoType.isYoutube && isCustomPlayer"
                :link="videoSrc"
                :host="playbackExtended ? playbackExtended.youtube_host || '' : ''"
                :is-vertical="isVertical"
                :key="'youtube-player-' + previewIndex"
                :parent="parent"
                :preview="preview"
                :is-show-preview-on-pause="isShowPreviewOnPause"
                :infoUnitId="infoUnitId"
                @showDefault="isCustomPlayer = false"
                @ready="playerReady"
                @play="videoStarted"
                @pause="handleCollectedData()"
                @ended="videoEnded()"
                @timeupdate="onPlayerTimeupdate($event)"
            />

            <!-- Is Youtube           -->
            <YoutubePlayer
                v-else-if="videoType.isYoutube"
                :videoUrl="videoSrc"
                :host="playbackExtended ? playbackExtended.youtube_host || '' : ''"
                :key="'youtube-player-' + previewIndex"
                :parent="parent"
                :preview="preview"
                :infoUnitId="infoUnitId"
                @ready="playerReady"
                @play="videoStarted"
                @pause="handleCollectedData()"
                @ended="videoEnded()"
                @timeupdate="onPlayerTimeupdate($event)"
            />
            <!-- Is Vimeo           -->
            <VimeoPlayer
                v-else-if="videoType.isVimeo"
                :videoUrl="videoSrc"
                :parent="parent"
                :preview="preview"
                :infoUnitId="infoUnitId"
                @ready="playerReady"
                @play="videoStarted"
                @pause="handleCollectedData()"
                @ended="videoEnded()"
                @timeupdate="onPlayerTimeupdate($event)"
            />

            <!-- Is Audio           -->
            <AudioPlayer
                v-else-if="videoType.isAudio"
                :audioUrl="videoSrc"
                :item="playback"
                :infoUnitId="infoUnitId"
                @play="videoStarted"
                @pause="handleCollectedData()"
                @ended="videoEnded()"
                @timeupdate="onPlayerTimeupdate($event)"
            />
        </template>
    </div>
</template>

<script>
import LessonRepository from "@lesson/shared/repository/lesson-repository"
import axios from "~axios"
import LoaderBlock from "@components/Loaders/LoaderBlock"
import { timeToSecond } from "@helpers"
import AlertBadConnection from "@components/Alerts/AlertBadConnection.vue"
import YouTubeVideoPlayer from "@components/Players/new/YoutubeVideoPlayer.vue"

export default {
    name: "PlayersWrapper",
    components: {
        YouTubeVideoPlayer,
        AlertBadConnection,
        VideojsPlayer: () => import("@components/Players/VideojsPlayer"),
        VideoUploadProgress: () => import("@components/Players/components/VideoUploadProgress"),
        BunnyPlayer: () => import("@components/Players/BunnyPlayer"),
        AudioPlayer: () => import("@components/Players/AudioPlayer"),
        VimeoPlayer: () => import("@components/Players/VimeoPlayer"),
        YoutubePlayer: () => import("@components/Players/YoutubePlayer"),
        LoaderBlock
        // PjsPlayer
    },
    props: {
        orientation: {
            type: String,
            required: false,
            default: "horizontal"
        },
        uuid: {
            type: [String, Number],
            required: false,
            default: ""
        },
        videoSrc: {
            type: String,
            required: true
        },
        videoTypeName: {
            type: String
        },
        playbackExtended: {
            type: Object,
            default: () => {
            }
        },
        isVertical: {
            type: Boolean
        },
        preview: {
            type: String,
            required: false
        },
        lessonId: {
            type: [String, Number],
            required: false
        },
        sourceType: {
            type: String,
            default: "info-units",
            required: false
        },
        parent: {
            type: String,
            required: false
        },
        previewIndex: {
            type: Number,
            required: false,
            default: 0
        },
        duration: {
            type: Number,
            required: false,
            default: 0
        },
        auth: {
            type: Boolean,
            default: false,
            required: false
        },
        isShowPreviewOnPause: {
            type: Boolean,
            default: false,
            required: false
        },
        isShowCustomYoutubePlayer: {
            type: Boolean,
            default: false,
            required: false
        },
        isAudio: {
            type: Boolean,
            default: false,
            required: false
        },
        isLive: {
            type: Boolean,
            default: false,
            required: false
        },
        liveStartTime: {
            type: String,
            default: null,
            required: false
        },
        displayMobileVersion: {
            type: Boolean,
            required: false,
            default: false
        },
        liveTrimEnding: {
            type: Number,
            default: 0,
            required: false
        },
        playback: {
            type: Object,
            required: false
        },
        lesson: {
            type: Object,
            required: false
        },
        infoUnitId: {
            type: [Number, String],
            required: false
        }
    },
    data() {
        return {
            videoType: null,
            isReady: false,
            isUploadedVideo: false,
            player: null,
            bpDownloadedData: {
                current: 0,
                saved: 0,
                count: 0
            },
            isCustomPlayer: this.isShowCustomYoutubePlayer,
            timeStamps: [],
            collectedStatData: {},
            timerForSendingData: 0,
            bpStartWatchingTime: 0,
            bpPlaybackPercentage: 0,
            finishingLessonCoeff: 0.85,
            seekTo: 0,
            lessonFinishingTime: null,
            videoStart: null,
            needScroll: false,
            isFinishingReportSent: false,
            didRerender: false
        }
    },
    computed: {
        displayProgressUpload() {
            if (this.isUploadedVideo) return false
            return !this.videoSrc || (this.playback && this.playback.process_status !== "completed")
        }
    },
    methods: {
        isIOS() {
            return (
                ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
                    navigator.platform
                ) ||
                // iPad on iOS 13 detection
                (navigator.userAgent.includes("Mac") && "ontouchend" in document)
            )
        },
        checkHashUrl(isScroll = false) {
            if (isScroll) {
                this.$nextTick(() => {
                    setTimeout(() => {
                        this.$el.scrollIntoView({ behavior: "smooth", block: "center" })
                    }, 0)
                })
                this.needScroll = false
            } else {
                const hashStr = window.location.hash
                const params = new URLSearchParams(hashStr.substring(1))
                if (!hashStr || !params.has("video-id")) {
                    return
                }
                const [unitId, time] = params.get("video-id").split("/")
                if (+unitId !== this.infoUnitId) {
                    return
                }
                this.videoStart = timeToSecond(time, false)
                this.needScroll = true
            }
        },
        rerenderPlayer(seek) {
            this.didRerender = true
            this.$nextTick(async () => {
                this.seekTo = seek

                const response = await axios.get(`info-units/${this.infoUnitId}`)

                const { files } = response.data.data

                if (files) {
                    console.log("rerender")
                    const [file] = files
                    this.$emit("update:link", file.url)

                    setTimeout(() => {
                        this.didRerender = false
                    }, 0)
                } else {
                    location.reload()
                }

                setTimeout(() => {
                    this.seekTo = 0
                }, 200)
            })
        },
        isNewPlayer() {
            return window.new_youtube_enabled
        },
        playerReady(player) {
            if (this.needScroll) {
                this.checkHashUrl(true)
            }
            if (player && this.videoStart) {
                if (this.videoType.isHls) player.currentTime(this.videoStart)
                else if (this.videoType.isYoutube) player.seekTo(this.videoStart)
                else if (this.videoType.isVimeo) player.setCurrentTime(this.videoStart)
            }
        },
        videoStarted() {
            if (this.parent === "expert") return
            let sourceTypeId = this.infoUnitId
            if (this.sourceType === "webinars") {
                sourceTypeId = this.lessonId
            }
            if (sourceTypeId) {
                axios.post(`/${this.sourceType}/${sourceTypeId}/events/started`)
            }
        },
        videoEnded(currentTime) {
            if (this.parent === "expert") return
            let sourceTypeId = this.infoUnitId
            if (this.sourceType === "webinars") {
                // eslint-disable-next-line no-unused-vars
                sourceTypeId = this.lessonId
            }
            if (this.lessonFinishingTime !== 0 && currentTime > this.lessonFinishingTime) {
                this.isFinishingReportSent = true

                let sourceTypeId = this.infoUnitId

                if (this.sourceType === "webinar") {
                    sourceTypeId = this.lessonId
                }

                if (sourceTypeId) {
                    axios.post(`/${this.sourceType}/${sourceTypeId}/events/watched`)

                    if (this.lesson && this.lesson.section_id) {
                        LessonRepository.replaceContents({ section: this.lesson.section_id })
                    }
                }
            }
        },
        isCollectedDataValid() {
            return !(
                Math.sign(this.collectedStatData.playback_percentage) === -1 ||
                Math.sign(this.collectedStatData.playback_min) === -1
            )
        },
        sendCollectedData() {
            // console.log("sourceType:", this.sourceType)
            if (this.sourceType === "webinars") return

            // Set data to default after one of user actions
            this.bpDownloadedData.current = 0
            this.bpDownloadedData.saved = 0
            this.bpDownloadedData.count = 0
            this.bpStartWatchingTime = 0
            this.bpPlaybackPercentage = 0

            // axios.post(`/lessons/${this.lessonId}/events/playback`, this.collectedStatData)
            // .then(response => console.log(response) )
            // .catch(e => console.log(e) )
        },
        handleCollectedData(time) {
            if (!this.isCollectedDataValid()) {
                return
            }

            if (this.auth && (this.parent === "lesson" || this.parent === "info-units")) {
                if (!time) {
                    clearInterval(this.timerForSendingData)
                    this.sendCollectedData()
                } else {
                    this.timerForSendingData = setInterval(() => {
                        this.sendCollectedData()
                    }, time)
                }
            }
        },
        onPlayerTimeupdate(player) {
            // console.log("onPlayerTimeupdate")
            let playerCurrentTime, videoDuration
            if (this.videoType.isVimeo || this.videoType.isYoutube) {
                playerCurrentTime = player.seconds
                videoDuration = player.duration
            } else {
                playerCurrentTime = player.currentTime()
                videoDuration = player.duration()
            }

            // Collect last 2 timestamps for calculating true data
            if (this.timeStamps.length > 2) {
                this.timeStamps.shift()
                this.timeStamps = [...this.timeStamps, playerCurrentTime]
            } else {
                this.timeStamps = [...this.timeStamps, playerCurrentTime]
            }

            this.$emit("player-current-time", playerCurrentTime)

            // Get video duration
            if (!this.lessonFinishingTime) {
                this.lessonFinishingTime = videoDuration * this.finishingLessonCoeff
            }

            // Start of detecting current video time
            if (
                this.auth &&
                (this.parent === "lesson" || this.parent === "info-units") &&
                !this.isFinishingReportSent
            ) {
                this.detectVideoFinishing(playerCurrentTime)
            }

            // Detecting time of starting watching video in current breakpoint
            if (this.auth && (this.parent === "lesson" || this.parent === "info-units") && !this.bpStartWatchingTime) {
                this.bpStartWatchingTime = playerCurrentTime
            }

            // Collect downloaded data size through a current breakpoint
            if (this.videoType.isVimeo || this.videoType.isYoutube) {
                this.collectedStatData.downloaded_size = 0
            } else {
                const valTest = 1073741824
                let bpDownloadedData = 0
                try {
                    // hls.js
                    if (player.tech_ && player.tech_.hlsjs) {
                        bpDownloadedData = player.tech_.hlsjs.bandwidthEstimate / valTest
                    }
                    // videojs
                    else if (player.tech({ IWillNotUseThisInPlugins: true })) {
                        bpDownloadedData = player.tech({ IWillNotUseThisInPlugins: true }).vhs.bandwidth / valTest
                    }
                    // eslint-disable-next-line no-empty
                } catch (e) {}
                this.bpDownloadedData.current = bpDownloadedData || 0

                if (this.bpDownloadedData.saved !== this.bpDownloadedData.current) {
                    this.bpDownloadedData.saved = this.bpDownloadedData.current

                    this.bpDownloadedData.count = this.bpDownloadedData.count + this.bpDownloadedData.saved
                }
            }

            // Get breakpoint playback percentage
            this.bpPlaybackPercentage = this.timeStamps[this.timeStamps.length - 2]
                ? Math.floor(
                      ((this.timeStamps[this.timeStamps.length - 2] - this.bpStartWatchingTime) / videoDuration) * 10000
                  ) / 100
                : Math.floor(((this.timeStamps[0] - this.bpStartWatchingTime) / videoDuration) * 10000) / 100

            // Collect data during watching
            this.collectedStatData = {
                downloaded_size: Number(this.bpDownloadedData.count.toFixed(4)),
                playback_percentage: this.bpPlaybackPercentage,
                playback_min: this.timeStamps[this.timeStamps.length - 2]
                    ? Number((this.timeStamps[this.timeStamps.length - 2] - this.bpStartWatchingTime).toFixed(4))
                    : Number((this.timeStamps[0] - this.bpStartWatchingTime).toFixed(4)),
                playback_start_min: Number(this.bpStartWatchingTime.toFixed(4)),
                playback_end_min: this.timeStamps[this.timeStamps.length - 2]
                    ? Number(this.timeStamps[this.timeStamps.length - 2].toFixed(4))
                    : Number(this.timeStamps[0].toFixed(4))
            }
        },
        detectVideoFinishing(currentTime) {
            if (this.parent === "expert") return
            if (this.lessonFinishingTime !== 0 && currentTime > this.lessonFinishingTime) {
                this.isFinishingReportSent = true
                let sourceTypeId = this.infoUnitId
                if (this.sourceType === "webinar") {
                    sourceTypeId = this.lessonId
                }
                axios.post(`/${this.sourceType}/${sourceTypeId}/events/watched`)

                if (this.lesson && this.lesson.section_id) {
                    LessonRepository.replaceContents({ section: this.lesson.section_id })
                }
            }
        },
        checkVideoType(url = "") {
            const youtubeMatches =
                url.match(
                    "^.*(?:(?:youtu\\.be\\/|v\\/|vi\\/|live\\/|u\\/\\w\\/|embed\\/)|(?:(?:watch)?\\?v(?:i)?=|\\&v(?:i)?=))([^#\\&\\?]*).*"
                ) && !url.match("ahacdn|ahvcdn|kwiga")
            const vimeoMatches =
                url.match("(https?:\\/\\/)?(www\\.)?(player\\.)?vimeo\\.com\\/([a-z]*\\/)*([‌​0-9]{6,11})[?]?.*") &&
                !url.match("ahacdn|ahvcdn|kwiga")
            const bunnyMatches = url.match("bunnycdn") || url.match("iframe.mediadelivery")
            let type = {
                isYoutube: !!youtubeMatches,
                isVimeo: !!vimeoMatches,
                isBunny: !!bunnyMatches,
                isAudio: this.isAudio,
                isHls: !youtubeMatches && !vimeoMatches && !bunnyMatches && !this.isAudio
            }

            if (type.isBunny) {
                type.isYoutube = false
            }

            const isHls = url.match(".+media=hls.+mp4$")

            if (this.videoTypeName) {
                type["name"] = this.videoTypeName
            } else if (youtubeMatches) {
                type["name"] = "video/youtube"
            } else if (vimeoMatches) {
                type["name"] = "video/vimeo"
            } else if (bunnyMatches) {
                type["name"] = "video/bunny"
            } else if (this.isAudio) {
                type["name"] = "video/mpeg"
            } else if (isHls) {
                type["name"] = "application/x-mpegURL"
            } else {
                type["name"] = "video/mp4"
            }
            return type
        }
    },
    created() {
        this.videoType = this.checkVideoType(this.videoSrc)
    },
    mounted() {
        this.checkHashUrl()
        this.$nextTick(() => {
            this.isReady = true
        })
    }
}
</script>

<style scoped>

</style>
